.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  background-color: #ffffff;
  color: #333;
  text-align: left; /* Left align text */
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.2);
  white-space: pre-line; /* Optional: preserves line breaks */
}

/* Show the tooltip on hover */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Example: Paragraph spacing within the tooltip */
.tooltip-text p {
  margin: 0.5rem 0;
}

/* Example: Title styling */
.tooltip-text strong {
  font-weight: 600;
}
